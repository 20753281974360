<template>
  <div>
    <div v-if="showMask" class="mask" @click.stop="onCloseMask" />
    <div class="nav-wrapper">
      <div class="left">
        <el-input
          v-model="searchKey"
          v-debounce="[onSearch, 'input']"
          class="input"
          clearable
          prefix-icon="el-icon-search"
          placeholder="请输入直播/会议名称"
          @clear="onSearch"
          @keydown.enter.native="onSearch"
        />
        <el-cascader
          v-model="searchObj.orgId"
          clearable
          :options="orgChildList"
          :props="defaultProps"
          :show-all-levels="false"
          placeholder="选择组织"
          class="select"
          @change="handleChange"
        />
        <el-date-picker
          :value="[startDate, endDate]"
          :disabled="isExport"
          type="daterange"
          range-separator="—"
          :default-time="['00:00:00', '23:59:59']"
          format="yyyy年MM月dd日"
          unlink-panels
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
          :editable="false"
          @change="onChangeDate"
        />
        &nbsp;
        <el-radio-group v-model="activeRadio" @change="onChangeRadio">
          <el-radio-button
            v-for="item in radioList"
            :key="item.key"
            :label="item.key"
          >
            {{ item.name }}
          </el-radio-button>
        </el-radio-group>
      </div>
      <div class="right">
        <el-row class="action-fix">
          <el-popover
            v-model="showExportPop"
            width="200"
            trigger="manual"
            placement="bottom"
          >
            <div v-if="exportMsg" class="lay-box">{{ exportMsg }}</div>
            <div v-else class="lay-box">
              是否导出{{ startDate }}至{{ endDate }}的数据?
            </div>
            <el-row type="flex" justify="end">
              <el-button size="mini" type="text" @click="showExportPop = false">
                取消
              </el-button>
              <el-button
                v-if="exportMsg"
                type="primary"
                size="mini"
                @click="onJumpDown"
              >
                前往
              </el-button>
              <el-button
                v-else
                type="primary"
                size="mini"
                :loading="isExport"
                @click="onExport"
              >
                确定
              </el-button>
            </el-row>
            <el-button
              v-if="!isMobile && $auth('acadSaas.dService.vBuy.exportData')"
              slot="reference"
              type="primary"
              icon="el-icon-plus"
              class="reset-btn"
              @click="onShowExportModal"
            >
              下载数据
            </el-button>
          </el-popover>
        </el-row>
      </div>
    </div>
    <div>
      <el-table
        v-loading="loading"
        width="100%"
        stripe
        :data="accountLivePayData"
      >
        <el-table-column
          prop="liveName"
          label="直播/会议名称"
          width="200"
          fixed="left"
          show-overflow-tooltip
        >
          <template slot-scope="scope">
            <div>
              <div class="flex text-primary-link">
                《<span @click.stop="onShowPreview(scope.row.liveId)">{{
                  scope.row.title
                }}</span>》
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          :key="sceneType"
          label="类型"
          width="150"
          show-overflow-tooltip
          align="center"
        >
          <template slot="header">
            <el-select
              v-model="sceneType"
              class="holder-color"
              clearable
              placeholder="类型"
              @change="
                () => {
                  pageNo = 1
                  init()
                }
              "
            >
              <el-option
                v-for="(item, index) in sceneList"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </template>
          <template slot-scope="scope">
            <span v-if="scope.row.sceneType === 5">直播</span>
            <span v-else-if="scope.row.sceneType === 6">会议</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="状态" width="120">
          <template slot-scope="scope">
            <span v-if="scope.row.reviewStatus === 0">待审核</span>
          </template>
        </el-table-column>
        <el-table-column
          label="播客"
          width="100"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.doctor.name }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="职称"
          width="100"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.doctor.titleName || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="department"
          label="科室"
          width="120"
          show-overflow-tooltip
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.doctor.department || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="医院"
          show-overflow-tooltip
          prop="hosiptal"
          align="center"
          width="120"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.doctor.hospital || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="开始时间" align="center" width="160">
          <template slot-scope="scope">
            <span>{{ scope.row.startAt || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column label="结束时间" width="160" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.finishAt || '—' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="orgName"
          align="center"
          show-overflow-tooltip
          label="创建组织"
          width="120"
        />
        <el-table-column
          prop="orgName"
          align="center"
          width="360"
          show-overflow-tooltip
          label="审核要求"
        >
          <template slot-scope="scope">
            <span
              class="rule-detail"
            ><i v-if="scope.row.liveStatus > 1" class="el-icon-success" /><i
              v-else
              class="el-icon-warning"
            />直播已结束
            </span>
            <span
              class="rule-detail"
            ><i v-if="scope.row.isSetReplay" class="el-icon-success" /><i
              v-else
              class="el-icon-warning"
            />已设置回放
            </span>
            <span
              class="rule-detail"
            ><i
              v-if="scope.row.isViewNumAndTimeOk"
              class="el-icon-success"
            /><i v-else class="el-icon-warning" />观看满{{
              scope.row.mustViewMin || 0
            }}分钟人数大于5人</span>
          </template>
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          :fixed="!isMobile ? 'right' : false"
          width="180"
        >
          <template slot-scope="scope">
            <el-row type="flex" align="middle" justify="center">
              <el-button
                v-if="scope.row.reviewStatus === 0 && $auth('acadSaas.dService.vBuy.sReview')"
                type="text"
                :disabled="
                  scope.row.liveStatus !== 3 ||
                    !scope.row.isSetReplay ||
                    !scope.row.isViewNumAndTimeOk
                "
                @click.stop="onSendLiveStatus(scope.row)"
              >
                {{
                  reviewLoading[`${scope.row.liveId}_${scope.row.doctor.id}`]
                    ? '提交中...'
                    : '提交审核'
                }}
              </el-button>
              <span v-if="scope.row.reviewStatus === 1">—</span>
              <el-link
                v-if="
                  scope.row.reviewStatus === 3 || scope.row.reviewStatus === 5
                "
                type="primary"
                :underline="false"
                @click.stop="onShowLookToast(scope.row)"
              >
                查看原因
              </el-link>
            </el-row>
          </template>
        </el-table-column>
      </el-table>
      <el-row type="flex" justify="center" class="t-p">
        <el-pagination
          background
          hide-on-single-page
          :current-page.sync="pageNo"
          :page-sizes="[10, 30, 50]"
          :page-size="pageSize"
          :layout="isMobile ? 'total, prev, pager, next' : 'total, prev, pager, next, jumper, sizes'"
          :total="totalCount"
          small
          @size-change="onChangeSize"
          @current-change="onChange"
        />
      </el-row>
    </div>
    <account-live-pay-modal
      :visible="showPayModal"
      :item="selectedPayItem"
      @onClose="onClose"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import AccountLivePayModal from '@/bizComponents/AccountLivePayModal.vue'
import { saveAs } from 'file-saver'
import { isMobile } from '@/utils/util.js'
export default {
  name: 'UnSubmitVideoList',
  components: {
    AccountLivePayModal
  },
  data() {
    return {
      radioList: [
        { name: '近一周', key: 'week' },
        { name: '近一月', key: 'month' },
        { name: '近三月', key: 'three_month' }
      ],
      selectedPayStatus: '',
      activeRadio: 'three_month', // message or download
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      showPreviewData: {},
      reviewLoading: {},
      showExportPop: false,
      selectedPayItem: {},
      popverTop: 0,
      popverLeft: 0,
      lookObj: {},
      exportMsg: '',
      showMask: false,
      showPayModal: false,
      loading: false,
      selectedOrgId: '',
      startDate: `${this.$moment()
        .add(-3, 'month')
        .format('YYYY-MM-DD HH:mm:ss')}`,
      endDate: `${this.$moment().format('YYYY-MM-DD HH:mm:ss')}`,
      isExport: false,
      cost: 0,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
          this.startDate = `${this.$moment(minDate).format(
            'YYYY-MM-DD'
          )} 00:00:00`
          this.endDate = `${this.$moment(maxDate).format(
            'YYYY-MM-DD'
          )} 23:59:59`
        },
        disabledDate: current => {
          const currentMin = current < this.$moment('2018-01-01')
          return currentMin
        }
      },
      searchKey: '',
      timer: null,
      sceneList: [
        {
          value: null,
          name: '所有类型'
        },
        {
          value: 5,
          name: '直播'
        },
        {
          value: 6,
          name: '会议'
        }
      ],
      sceneType: null,
      searchObj: {
        orgId: ''
      },
      defaultProps: {
        children: 'child',
        label: 'orgName',
        value: 'orgId',
        disabled: 'isSelect',
        checkStrictly: true
      },
      isPay: null,
      remark: '',
      bindLoading: false,
      payLiveFeeBOS: [],
      isMobile: isMobile(),
      toReviewStatus: 0,
      options: [
        {
          value: null,
          label: '全部'
        },
        {
          value: 0,
          label: '待审核'
        },
        {
          value: 1,
          label: '后台审核中'
        },
        {
          value: 3,
          label: '审核失败'
        },
        {
          value: 5,
          label: '拒绝采购'
        },
        {
          value: 6,
          label: '支付过期'
        }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'accountLivePayData',
      'orgList',
      'factoryId',
      'liveQrCodePreviewData'
    ]),
    orgChildList() {
      return this.orgList
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    ...mapActions([
      'onExportAccountLivePayData',
      'getAccountLivePayDetail',
      'getLivePreviewQrCode',
      'onReviewLiveAccount'
    ]),
    onSearch() {
      this.timer && clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.pageNo = 1
        this.init()
      }, 800)
    },
    onCloseMask() {
      this.showMask = false
      this.showPreviewData = {}
      this.showExportPop = false
    },
    onShowPreview(liveId) {
      const routeUrl = this.$router.resolve(`/academic/live/detail/${liveId}`)
      window.open(routeUrl.href, '_blank')
    },
    onShowExportModal() {
      this.showMask = true
      this.showExportPop = true
    },
    onJumpDown() {
      this.$router.push({name: 'DownloadCenter'})
    },
    onExport() {
      if (!this.accountLivePayData.length)
        return this.$message.error('暂无需要导出的数据')
      if (!this.$moment(this.startDate).isValid())
        return this.$message.error('请选择开始日期')
      if (!this.$moment(this.endDate).isValid())
        return this.$message.error('请选择结束日期')
      if (this.isExport) return
      this.isExport = true
      const req = {
        startAt: `${this.$moment(this.startDate).format(
          'YYYY-MM-DD'
        )} 00:00:00`,
        endAt: `${this.$moment(this.endDate).format('YYYY-MM-DD')} 23:59:59`,
        targetOrgId: this.selectedOrgId,
        reviewStatus: this.toReviewStatus,
        keyword: this.searchKey,
        sceneType: this.sceneType
      }
      this.onExportAccountLivePayData(req).then(
        res => {
          this.isExport = false
          this.exportMsg = res.data
          this.$message.success(this.exportMsg)
        },
        rea => {
          this.isExport = false
          if (rea.code === 6007) {
            this.exportMsg = rea.message
          }
          this.$message.error(rea.message)
        }
      )
    },
    onClose() {
      this.showPayModal = false
      this.selectedPayItem = {}
    },
    onShowLookToast(row) {
      const reason = row.reviewStatus === 3 ? '审核失败原因' : '拒绝采购原因'
      this.$MessageDialog({
        title: reason,
        message: `${reason}：${row.remark}`,
        submitText: '我知道了',
        cancelText: '前往编辑'
      })
        .then(() => {})
        .catch(() => {
          const sceneType = row.sceneType === 5 ? 'live' : 'webinar'
          const routeUrl = this.$router.resolve(
            `/academic/scene/${sceneType}/edit/${row.liveId}`
          )
          window.open(routeUrl.href, '_blank')
        })
    },
    onSaveUrl(v) {
      if (!v.downloadUrl) return this.$message.error('暂无视频下载资源')
      saveAs(v.downloadUrl)
      this.$message.success('操作成功，视频下载中，请耐心等待')
    },
    onShowPayToast(item) {
      if (item.liveStatus < 3 && !item.downloadUrl)
        return this.$message.error('直播结束且设置回放后，方可支付视频采购费')
      this.showPayModal = true
      this.selectedPayItem = item
    },
    onSendLiveStatus(item) {
      if (this.reviewLoading[`${item.liveId}_${item.doctor.id}`]) return
      this.reviewLoading[`${item.liveId}_${item.doctor.id}`] = true
      const req = {
        liveId: item.liveId,
        doctorId: item.doctor.id
      }
      this.onReviewLiveAccount(req).then(
        () => {
          this.reviewLoading[`${item.liveId}_${item.doctor.id}`] = false
          this.$message.success('审核已提交至后台')
        },
        rea => {
          this.reviewLoading[`${item.liveId}_${item.doctor.id}`] = false
          this.$message.error(rea.message)
        }
      )
    },
    init() {
      if (!this.$moment(this.startDate).isValid())
        return this.$message.error('请选择开始日期')
      if (!this.$moment(this.endDate).isValid())
        return this.$message.error('请选择结束日期')
      this.loading = true
      const req = {
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        targetOrgId: this.selectedOrgId,
        reviewStatus: this.toReviewStatus,
        startAt: `${this.$moment(this.startDate).format(
          'YYYY-MM-DD'
        )} 00:00:00`,
        endAt: `${this.$moment(this.endDate).format('YYYY-MM-DD')} 23:59:59`,
        keyword: this.searchKey,
        sceneType: this.sceneType
      }
      this.getAccountLivePayDetail(req).then(
        res => {
          this.totalCount = res.totalCount
          this.exportMsg = ''
          this.showPreviewData = {}
          this.loading = false
        },
        rea => {
          this.loading = false
          this.$message.error(rea.message)
        }
      )
    },
    onSelectOrg({ orgId }) {
      this.selectedOrgId = orgId
      this.pageNo = 1
      this.init()
    },
    onChangeRadio(val) {
      this.activeRadio = val
      switch (this.activeRadio) {
        case 'week':
          this.startDate = this.$moment()
            .add(-1, 'weeks')
            .format('YYYY-MM-DD HH:mm:ss')
          this.endDate = this.$moment()
            .add(0, 'days')
            .format('YYYY-MM-DD HH:mm:ss')
          break
        case 'month':
          this.startDate = this.$moment()
            .add(-1, 'months')
            .format('YYYY-MM-DD HH:mm:ss')
          this.endDate = this.$moment()
            .add(0, 'days')
            .format('YYYY-MM-DD HH:mm:ss')
          break
        case 'three_month':
          this.startDate = this.$moment()
            .add(-3, 'months')
            .format('YYYY-MM-DD HH:mm:ss')
          this.endDate = this.$moment()
            .add(0, 'days')
            .format('YYYY-MM-DD HH:mm:ss')
          break
      }
      this.pageNo = 1
      this.init()
    },
    onChangeDate(val) {
      if (val === null) {
        this.startDate = this.$moment(this.startDate).format(
          'YYYY-MM-DD HH:mm:ss'
        )
        this.endDate = this.$moment(this.endDate).format('YYYY-MM-DD HH:mm:ss')
      }
      this.pageNo = 1
      this.activeRadio = ''
      this.init()
    },
    onChange(pageNo) {
      this.pageNo = pageNo
      this.init()
    },
    onChangeSize(pageSize) {
      this.pageSize = pageSize
      this.init()
    },
    handleChange(value) {
      console.log(value)
      this.$set(this.searchObj, 'orgId', value)
      this.onSelectOrg({
        orgId: this.searchObj.orgId[this.searchObj.orgId.length - 1]
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.margin-l {
  margin-left: 14px;
}
.lay-box {
  padding-top: 12px;
  padding-bottom: 12px;
}
.valid-color {
  color: #fb9c35;
}
.color-red {
  color: #fb4e62;
}
.l-m {
  margin-left: 10px;
}
.t-p {
  margin-top: 15px;
}
.el-icon-success {
  color: #30c691;
  font-size: 16px;
}
.el-icon-warning {
  color: $color-assist;
  font-size: 16px;
}
.rule-detail > i {
  padding: 0 2px 0 5px;
  vertical-align: center;
}
.action-fix {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
}
.reset-btn {
  margin-left: 10px;
}
.nav-wrapper {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
  }
  .left {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .select {
      width: 120px;
      ::v-deep .el-select__tags {
        > span {
          width: 100%;
          display: grid;
          grid-template-columns: auto auto;
          > span {
            border: none;
            overflow: hidden;
            .el-tag__close.el-icon-close {
              background-color: transparent;
              &:hover {
                background-color: #c0c4cc;
                color: #909399;
              }
            }
          }
        }
      }
    }
    .input {
      width: 200px;
      ::v-deep .el-input__inner {
        background-color: #fafafa;
        border-radius: 20px;
      }
    }
  }
  .right {
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    gap: 9px;
  }
}
</style>
